import React, { useEffect, useState } from "react";

import { TabBar, MobNavbar } from "../../shared";
import { useDispatch, useSelector } from "react-redux";
import { getMyMedia } from "../../actions";
import ShauryaLoader from "../../elements/shauryaLoader";

import { TrialList } from "./trials";
import { TournamentList } from "./tournaments";

import "./styles.css";
import { LeaguesList } from "./leagues";

const TABS = [
  { key: "trial", value: "Trials" },
  { key: "tournament", value: "Tournaments" },
  { key: "league", value: "Corporate" },
];

const AllVideos = (props) => {
  const dispatch = useDispatch();
  const tabName = props.location?.state?.tabName || "trial";
  const eventName = props.location?.state?.name || "My Videos";
  const [colorChange, setColorchange] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabName);

  const { media, loading } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getMyMedia());
  }, []);

  const changeNavbarColor = () => {
    if (window.scrollY >= 30) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);

  const renderTabs = () => {
    return (
      <div
        className={
          colorChange
            ? "championship-tabs is-flex is-flex-direction-row inverted"
            : "championship-tabs is-flex is-flex-direction-row"
        }
      >
        {TABS.map((tab, index) => (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setSelectedTab(tab.key)}
            className={
              tab.key === selectedTab
                ? "championship-tabs-item active"
                : "championship-tabs-item"
            }
            key={index.toString()}
          >
            <span className="is-inline-block is-size-7 pc has-text-weight-bold">
              {tab.value}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const toggleTabComponent = (selectedTab) => {
    selectedTab = selectedTab.toLowerCase();
    switch (selectedTab) {
      case "trial": {
        return <TrialList data={media.trials.events} />;
      }
      case "tournament": {
        return <TournamentList data={media.tournaments.events} />;
      }
      case "league": {
        return <LeaguesList data={media.leagues.events} />;
      }
      default: {
        return <p>default</p>;
      }
    }
  };

  return (
    <>
      <MobNavbar
        title={eventName}
        subHeading="All your videos in one place"
      />
      <div className="container mob-league-container">
        {renderTabs()}
        {loading.media ? <ShauryaLoader /> : toggleTabComponent(selectedTab)}
      </div>
      <TabBar />
    </>
  );
};

export default AllVideos;
