import React from "react";
import { useHistory } from "react-router";

export const LeaguesList = ({ data }) => {
  const history = useHistory();
  const openAllVideos = (id) => {
    history.push({
      pathname: `/my-videos/leagues/${id}`
    });
  };

  if (!data.length) {
    return (
      <div
        className="text-center"
        style={{
          height: "300px",
          width: "100%",
          margin: "100px 0px",
        }}
      >
        <strong className="pc">No Videos Available!</strong>
      </div>
    );
  }

  return (
    <div className="columns is-multiline is-1 is-mobile">
      {data.map((item) => {
        return (
          <div key={item.name} className="column is-6 is-12-mobile p-2">
            <div
              className="teams-block align-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                openAllVideos(item["_id"]);
              }}
            >
              <img
                src={
                  item.image
                    ? item.image
                    : "https://app.shauryasports.com/static/media/logo_theme.7d7df77c.png"
                }
                alt={"event-logo"}
                style={{
                  objectFit: "cover",
                  objectPosition: "top",
                  height: 64,
                  width: 64,
                  marginBottom: "16px",
                }}
              />
              <h3 className="has-text-weight-bold pc is-size-5">{item.name}</h3>
              <p className="sc">{item["videoCount"]} Videos</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
