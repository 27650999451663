let base_url = "https://ws.shauryasports.com/";
let razorpay_key = "rzp_test_pdonTvteD1YPQE";
// let base_url = "http://localhost:9000/";
// http://192.168.1.2:9000/
if (process.env.NODE_ENV == "production") {
  base_url = "https://ws.shauryasports.com/";
  razorpay_key = "rzp_live_6Mc6J0vBDeI36u";
}

export { base_url, razorpay_key };
