import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import YTPlayer from "../../elements/yt-player";
import { MobNavbar, TabBar } from "../../shared";
import { getMyMedia } from "../../actions";
import "./styles.css";

const VideoListing = (props) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const { media, loading } = useSelector((state) => state.user);

  useEffect(() => {
    if (loading.media) {
      dispatch(getMyMedia());
    }
  }, []);

  const {
    match: {
      params: { id, event },
    },
  } = props;

  let videoData = media[event]["videos"] || {};

  const setHLVideoPlayerDetails = (videoSrc) => {
    setIsModelOpen(!isModalOpen);
    setVideoSrc(videoSrc);
  };

  const removeHLVideoPlayerDetails = () => {
    setIsModelOpen(false);
    setVideoSrc("");
  };

  const videoRow = () => {
    return (
      <div className="gallery-wrapper">
        {videoData?.[id] && videoData[id].length ? (
          <>
            {videoData[id].map((item, index) => {
              return (
                <div
                  key={index}
                  className="gallery-video-item"
                  onClick={() => setHLVideoPlayerDetails(item.media_url)}
                >
                  <div className="gallery-video-backdrop" />
                  <div className="gallery-title-block">
                    <img
                      alt="play_button"
                      src="https://img.icons8.com/ios-filled/2x/ffffff/circled-play.png"
                    />
                    {/* <h4 className="gallery-video-title">{item.media_url}</h4> */}
                  </div>
                  <img
                    className="gallery-video-banner"
                    alt="video_banner"
                    style={{ objectFit: "contain" }}
                    src={
                      "https://s3.ap-south-1.amazonaws.com/static.shauryasports.com/logos/logo_transparent.png"
                    }
                  />
                </div>
              );
            })}
          </>
        ) : (
          <div
            className="text-center"
            style={{
              height: "300px",
              width: "100%",
              margin: "100px 0px",
            }}
          >
            <strong className="pc">No Videos Available!</strong>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <MobNavbar title={"Match Videos"} subHeading="Your Performance" />

      <div className=" mob-league-container">{videoRow()}</div>
      <TabBar />
      <YTPlayer
        handlePlayerClose={() => removeHLVideoPlayerDetails()}
        show={isModalOpen}
        source={videoSrc}
        type="native"
      />
    </>
  );
};

export default VideoListing;
