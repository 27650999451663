import INITIAL_STATE from "./models/user"; // Import Initial State
import {
  GET_MY_MEDIA,
  UPDATE_MY_PROFILE,
  GET_MY_PROFILE,
  GET_ALL_UPDATES,
  GET_USER_NOTIFICATIONS,
} from "../actionTypes"; // Import Action Types

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MY_PROFILE:
      return {
        ...state,
        profile: { ...action.payload.profile },
        loading: { ...state.loading, profile: false },
      };
    case UPDATE_MY_PROFILE:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload.profile },
      };
    case GET_ALL_UPDATES:
      return {
        ...state,
        allUpdates: [...action.payload.liveupdates],
        loading: { ...state.loading, allUpdates: false },
      };
    case GET_USER_NOTIFICATIONS:
      return {
        ...state,
        userNotifications: [...action.payload.notifications],
        loading: { ...state.loading, userNotifications: false },
      };
    case GET_MY_MEDIA:
      console.log("media", action.payload);
      const { trial_media, tournament_media, leagues_media } = action.payload;
      let trialsData = [];
      let tournamentData = [];
      let leaguesData = [];
      let trialVideos = {};
      let tournamentVideos = {};
      let leagueVideos = {};
      trial_media.forEach((item) => {
        trialsData.push({ ...item.trial, videoCount: item.data.length });
        trialVideos[item.trial["_id"]] = item.data;
      });
      tournament_media.forEach((item) => {
        tournamentData.push({
          ...item.tournament,
          videoCount: item.data.length,
        });
        tournamentVideos[item.tournament["_id"]] = item.data;
      });
      leagues_media.forEach((item) => {
        leaguesData.push({
          ...item.league,
          videoCount: item.data.length,
        });
        leagueVideos[item.league["_id"]] = item.data;
      });
      
      return {
        ...state,
        loading: { ...state.loading, media: false },
        media: {
          trials: {
            events: trialsData,
            videos: trialVideos,
          },
          tournaments: {
            events: tournamentData,
            videos: tournamentVideos,
          },
          leagues: {
            events: leaguesData,
            videos: leagueVideos,
          },
        },
      };
    default:
      return state;
  }
};
