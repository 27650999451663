const INITIAL_STATE = {
  profile: {},
  userNotifications: [],
  allUpdates: [],
  media: {
    trials: {
      events: [],
      videos: {},
    },
    tournaments: {
      events: [],
      videos: {},
    },
    leagues: {
      events: [],
      videos: {},
    },
    profile: {
      videos: [],
    },
  },
  loading: {
    profile: true,
    allUpdates: true,
    userNotifications: true,
    media: true,
  },
};

export default INITIAL_STATE;
